html, body {
    width: 100%;
    height: 100%;
}
@layer mantine, mantine-datatable;

/*
prevents printing
 */
@media print {
    html, body {
        display: none;
    }
}